import * as React from 'react';
import { Container } from 'reactstrap';
import BaseLayout from '../components/Layouts/BaseLayout';

const ContactPage = ({ location }) => {
  return (
    <BaseLayout location={location} title="Contact">
      <header className="page-header small bg-secondary">
        <Container>
          <div className="header-content text-center">
            <h1 className="header-title bg-transparent">CONTACT JUSTIN</h1>
          </div>
        </Container>
      </header>

      <section className="big-section">
        <Container>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              (async () => {
                try {
                  const form = e.target;
                  const data = new FormData(form);
                  data.set('site', 'justinscroggins');
                  const response = await fetch('https://contact.scottrupprecht.com/contact.php', {
                    method: 'POST',
                    body: data,
                  });

                  if(response.status === 200) {
                    alert('Thank you for your message. I appreciate your interest and will get back to you shortly.');
                    form.reset();
                  }
                } catch (err) {
                  console.error(err);
                }
              })();
            }}
          >
            <div className="row">
              <div className="col-md-6 sub-col-left">
                <div className="form-group">
                  <input name="name" type="text" className="form-control" placeholder="*Name" required />
                </div>
              </div>
              <div className="col-md-6 sub-col-right">
                <div className="form-group">
                  <input name="email" type="email" className="form-control" placeholder="*Email" required />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-2">
                  <textarea name="message" className="form-control" rows={5} placeholder="*Message" required />
                </div>
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-submit btn-primary rectangle" >
                <span>Send Message</span>
              </button>
            </div>
          </form>
        </Container>
      </section>
    </BaseLayout>
  );
};

export default ContactPage;
